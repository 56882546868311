<template>
  <lottie-player
    src="MapAnimations.json"
    background="transparent"
    speed="0.2"
    style="width: 100%; height: auto;"
    loop
    autoplay
  >
  </lottie-player>
</template>
